import type { FC } from 'react'

import type { BenefitsMultipleTextType } from '@Types/blocks/benefitsMultipleText'
import type { ExtendBlocksType } from '@Types/blocks/blocks'

import { BenefitsMultipleTextContainer, List, ListItem, SubTitle, Title, Wrapper } from './styles'

type Props = {
  data: ExtendBlocksType
}

export const BenefitsMultipleText: FC<Props> = ({ data }) => {
  const { subtitle, texts, title } = data as BenefitsMultipleTextType

  return (
    <BenefitsMultipleTextContainer data-block={data.type}>
      <Wrapper>
        <Title size="large-strong" tag="h3">
          {title}
        </Title>
        <SubTitle size="large">{subtitle.value}</SubTitle>
      </Wrapper>

      <List>
        {texts.map((text, index) => (
          <ListItem dangerouslySetInnerHTML={{ __html: text }} key={index} size="small" tag="li" />
        ))}
      </List>
    </BenefitsMultipleTextContainer>
  )
}
