import styled, { type CSSProperties } from 'styled-components'

import type { TypographySizeType } from '../../types/typography/typographySizes'

export const HeadingStyled = styled.h1<{
  $color: CSSProperties['color']
  $size: TypographySizeType
}>`
  margin: 0;
  color: ${p => p.$color};
  ${p => p.theme.typography.desktop.heading[p.$size].$value}

  ${p => p.theme.mediaQueries.mobile} {
    ${p => p.theme.typography.mobile.heading[p.$size].$value}
  }
`
