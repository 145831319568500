import BodyText from 'firebird-ui/src/components/BodyText'
import Heading from 'firebird-ui/src/components/Heading'
import styled from 'styled-components'

export const BenefitsMultipleTextContainer = styled.section`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 48px;

  ${p => p.theme.mediaQueries.mobileTablet} {
    row-gap: 40px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    row-gap: 16px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding-inline: 104px;

  ${p => p.theme.mediaQueries.mobileTablet} {
    row-gap: 24px;
    padding-inline: 36px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    row-gap: 12px;
    padding-inline: 0;
  }
`

export const Title = styled(Heading)`
  max-width: 520px;
`

export const SubTitle = styled(BodyText)`
  max-width: 600px;
`

export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;

  ${p => p.theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;
  }
`

export const ListItem = styled(BodyText)`
  position: relative;
  list-style-type: none;
  color: ${p => p.theme.colors.grey8D};
`
